import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import showdown from 'showdown'

export const VedtekterPageTemplate = ({ title, page_name, description, image, content }) => {
  const converter = new showdown.Converter();
  const backgroundImage = typeof image === 'string' ? image : image.childImageSharp.fluid.src

   const renderFooterAction = page_name => {
    const pages = {
      home: {
        display: 'Hjem',
        next: '/stiftelsen'
      },
      stiftelsen: {
        display: 'Stiftelsen',
        next: '/styret',
        prev: '/'
      },
      styret: {
        display: 'Vedtekter',
        next: '/formal',
        prev: '/stiftelsen'
      },
      formal: {
        display: 'Formål',
        next: '/fn',
        prev: '/styret'
      },
      fn: {
        display: 'FNs bærekraftsmål',
        next: '/vedteker',
        prev: '/formal'
      },
      vedtekter: {
        display: 'Vedtekter',
        next: '/samarbeidspartnere',
        prev: '/fn'
      },
      samarbeidspartnere: {
        display: 'Samarbeidspartnere',
        prev: '/vedtekter'
      },
    }

    const page = pages[page_name];

    return (
      <div className='footer-action'>
        <Link to={page.prev}  disabled={page.prev == undefined}  className={ `prev ${ page.prev == undefined ? 'disabled' : '' }` } >
          {"<  "}
        </Link>

        <span>{ page.display }</span>

        <Link to={page.next} disabled={page.next == undefined}  className={ `next ${ page.next == undefined ? 'disabled' : '' }` }>
          {"  >"}
        </Link>
      </div>
    )
  }

  return (
  <main id="index" className="foundation" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
    </Helmet>
    <Navbar page_name={page_name}/>

    <section className="hero is-fullheight">
      <div className="mt-12">
        <div className="container main-section">
          <div className="columns">
            <div className="column">
            </div>

            <div className="column is-three-quarters">
                <div className="content has-text-white">
                <h1>Vedtekter</h1>
                <h2>(Utdrag)</h2>

                <div className="paragraph-block">
                  <p className="paragraph-number">§ 1</p>
                  <p className="paragraph-text">
                    Stiftelsen Signe Marie ble opprettet som Åge Lærdal Stiftelsen etter en donasjon fra han alene 30.12.2005.
                  </p>
                </div>
                <div className="paragraph-block">
                  <p className="paragraph-number">§ 3</p>
                  <p className="paragraph-text">
                    Stiftelsen har sete i Sola kommune.
                  </p>
                </div>           
                
                <div className="paragraph-block">
                  <p className="paragraph-number">§ 5</p>
                  <p className="paragraph-text">
                    Stiftelsens grunnkapital er 49.000.000,-
                  </p>
                </div>
                
                <div className="paragraph-block">
                  <p className="paragraph-number">§ 6</p>
                  <p className="paragraph-text">
                      Stiftelsens styre skal bestå av 3–7 medlemmer hvorav 2 kan være varamedlemmer.<br></br>
                      Styret velges av et samlet styre etter forslag fra Åge Lærdal.
                  </p>
                </div>

                <div className="paragraph-block">
                  <p className="paragraph-number">§ 7</p>
                  <p className="paragraph-text">
                    Stiftelsen skal ikke ha daglig leder.
                  </p>
                </div>        
                
                <div className="paragraph-block">
                  <p className="paragraph-number">§ 8</p>
                  <p className="paragraph-text">
                    Styret velger revisor for stiftelsen.
                  </p>
                </div>     
                
                
                <div className="paragraph-block">
                  <p className="paragraph-number">§ 9</p>
                  <p className="paragraph-text">
                    For øvrig henvies til enhver gjeldende lovgivning for stiftelser.
                  </p>
                </div>

              </div>
            </div>

            <div className="column">
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer>
      {renderFooterAction(page_name)}
    </footer>
  </main>
  )
}

VedtekterPageTemplate.propTypes = {
  image: PropTypes.object.isRequired,
  page_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

const VedtekterPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <VedtekterPageTemplate
        page_name={post.frontmatter.page_name}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        content={post.frontmatter.content}
      />
    </Layout>
  )
}

VedtekterPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default VedtekterPage

export const VedtekterPageQuery = graphql`
  query VedtekterPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        page_name
        title
        description
        content
      }
    }
  }
`
